// ProtectedArea.js
import React from 'react';

const ProtectedArea = () => {
  return (
    <div className="protected-area">
      <h1 className="heading">Welcome to the Protected Area!</h1>
      <p className="subheading">This content is only accessible with the correct private key.</p>
    </div>
  );
};

export default ProtectedArea;